<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='name'>
          <a-input v-model:value="search.keywords" :style="{width:'250px'}" placeholder="请输入品牌商编号/名称/账号"></a-input>
        </a-form-item>
        <a-form-item label="审核状态" name='ali_status'>
          <a-select v-model:value="search.ali_status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="0">未提交审核</a-select-option>
            <a-select-option :value="1">已提交审核</a-select-option>
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核不通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="创建时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" ghost v-has:signRefreshAli='refresh'>刷新查询阿里审核状态</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:ali_status_text="{ record }">
          <p v-if='record.ali_status!=3'>{{record.ali_status_text}}</p>
          <a-tooltip placement="top" v-if='record.ali_status==3'>
            <template #title>
              {{record.remark}}
            </template>
            <a href="javascript:;">{{record.ali_status_text}}</a>
          </a-tooltip>
        </template>
        <template v-slot:remark="{ record }">
          <p style="max-width: 500px;" v-if="record.remark">{{record.remark}}</p>
          <p v-else>-</p>
        </template>
        <template v-slot:sign="{ record }">
          <p style="max-width: 500px;" v-if="record.sign">{{record.sign}}</p>
          <p v-else>-</p>
        </template>
        <template v-slot:action="{ record }">
          <a-button type="primary" size="small" class="m-r-10" v-has:signSubmitAli="()=>submitAli(record)" :disabled='record.ali_status !== 0'>提交至阿里云</a-button>
          <a-button type="primary" size="small" ghost v-has:signDetail="()=>{$router.push('/brandMsg/signReviewed/signDetail?id='+record.id)}">详情</a-button>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
    <a-modal v-model:visible="visible" title="消息模板审核" @ok="handleOk">
      <a-radio-group v-model:value="valueRadio" @change="onRadioChange">
        <a-radio :value="1">
          通过
        </a-radio>
        <a-radio :value="0">
          拒绝
        </a-radio>
      </a-radio-group>
      <a-textarea v-if='valueRadio == 0' class="mt15" v-model:value="refuseText" placeholder="请输入拒绝的原因" auto-size />
    </a-modal>
  </a-layout>
</template>
<script>
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { message } from "ant-design-vue";
import { getRouter } from "@/utils/app";
import { setPageState, getPageState } from "@/utils/pageState";
import { $iscode } from "@/utils/app";
import {
  getSignedList,
  setSigned,
  setSignedStatus,
  SignedRefresh,
} from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  start_time: "",
  end_time: "",
  page: 1,
  limit: 10,
  ali_status: "",
};
export default {
  components: {},
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      visible: false,
      total: 1,
      valueRadio: null,
      refuseText: "",
      time: [],
      brand_id: "",
    });
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "公司名称",
        dataIndex: "brand_company",
        key: "brand_company",
      },
      {
        title: "品牌商账号",
        dataIndex: "brand_account",
        key: "brand_account",
      },
      {
        title: "审核短信签名内容",
        dataIndex: "sign",
        key: "sign",
        slots: { customRender: "sign" },
      },
      {
        title: "申请时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "阿里云审核",
        dataIndex: "ali_status_text",
        key: "ali_status_text",
        slots: { customRender: "ali_status_text" },
      },
      {
        title: "审核反馈",
        dataIndex: "remark",
        key: "remark",
        slots: { customRender: "remark" },
      },
      {
        title: "操作",
        key: "action",
        fixed:"right",
        slots: { customRender: "action" },
      },
    ];
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    // 获取列表
    const initData = async (values) => {
      state.listLoading = true;
      try {
        let res = await getSignedList(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };
    const submitAli = (e) => {
      $Modal.confirm({
        title: "提交至阿里云",
        content: "您确定要将该消息模板提交至阿里云审核吗？",
        centered:true,
        onOk: async () => {
          let res = await setSigned(e.id).then((res) => res.data);
          if ($iscode(res)) {
            message.success(res.msg);
            initData(search.value);
          } else {
            message.error(res.msg);
          }
        },
      });
    };
    const msgReview = (e) => {
      state.visible = true;
      state.brand_id = e.id;
    };
    // 时间筛选
    const onChange = (e) => {
      if (e.length > 0) {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
      } else {
        search.value.start_time = "";
        search.value.end_time = "";
      }
    };
    //确认提交
    const handleOk = async () => {
      let res = await setSignedStatus(state.brand_id, state.valueRadio, {
        reason: state.refuseText,
      }).then((res) => res.data);
      if ($iscode(res)) {
        message.success(res.msg);
        state.visible = false;
        initData(search.value);
      } else {
        message.error(res.msg);
      }
    };
    //选择是否通过
    const onRadioChange = (e) => {
      state.valueRadio = e.target.value;
    };
    //刷新
    const refresh = async () => {
      const hide = message.loading("正在加载中...", 0);
      let res = await SignedRefresh().then((res) => res.data);
      if ($iscode(res)) {
        setTimeout(hide);
        message.success(res.msg);
        initData(search.value);
      } else {
        setTimeout(hide);
        message.error(res.msg);
      }
    };
    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if (pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      handleOk,
      onRadioChange,
      msgReview,
      refresh,
      submitAli,
    };
  },
};
</script>
<style scoped>
.s_omit2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>